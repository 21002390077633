import {
  PageLayout,
  CTALink,
  InspirationGallery,
  InspirationFilterList,
  InspirationGalleryModal,
} from 'components';
import React, { FC, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { SeoType } from '../pages/index';

interface InspirationdPageTypes {
  data: {
    InspirationPage: {
      title: string;
      seo: SeoType;
      callToAction: {
        title: string;
        link: string;
      };
      quotation: {
        content: string;
      };
    };
    InspirationImages: {
      edges: {
        node: {
          strapiId: number;
          title: string;
          image: {
            childImageSharp: {
              fluid: {
                base64: string;
                aspectRatio: number;
                src: string;
                srcSet: string;
                sizes: string;
              };
            };
          };
        };
      }[];
    };
    InspirationCategories: {
      edges: {
        node: {
          id: string;
          title: string;
          // eslint-disable-next-line camelcase
          inspiration_tags: {
            id: string;
            title: string;
          }[];
        };
      }[];
    };
  };
  pageContext: any;
}

const Inspiration: FC<InspirationdPageTypes> = ({ data, pageContext }) => {
  const { activeImage } = pageContext;
  const {
    InspirationPage: {
      callToAction: { link, title: callToActionTitle },
      quotation: { content: quotation },
      title,
      seo,
    },
    InspirationImages,
    InspirationCategories: { edges: inspirationCategories },
  } = data;

  const cmsFilters: any = [];

  if (inspirationCategories) {
    inspirationCategories.forEach(inspirationCategory => {
      const {
        node: { title: categoryTitle, inspiration_tags: inspirationTags },
      } = inspirationCategory;

      const category = {
        title: categoryTitle,
        options: inspirationTags.map(tag => tag.title),
      };
      cmsFilters.push(category);
    });
  }

  const PageFilterKeys: any = {};

  cmsFilters.forEach((filter: any) => {
    PageFilterKeys[filter.title] = [];
  });

  const Limit = 12;
  const [PageFilter, setPageFilter]: any = useState(PageFilterKeys);
  const [FilteredInspirationImages, setFilteredInspirationImages] = useState(
    InspirationImages?.edges
  );
  const [imagesToDisplay, setImagesToDisplay] = useState(9);
  const [loadMore, setLoadMore] = useState(false);
  const [ModalImageIndex, setModalImageIndex]: any = useState(null);
  const [isModalOpen, setisModalOpen] = useState(false);

  const loadMoreBlog = () => {
    const newIndex = imagesToDisplay + Limit;
    const newShowMore = newIndex < FilteredInspirationImages.length - 1;
    setImagesToDisplay(newIndex);
    setLoadMore(newShowMore);
  };

  const handleModalOpen = (item: any) => {
    setisModalOpen(true);
    setModalImageIndex(FilteredInspirationImages.indexOf(item));
    const slug = `${item.node.title
      .replace(/[^a-zA-Z\d:]/g, '-')
      .toLowerCase()}-${item.node.strapiId}`;
    window.history.pushState('', '', `/gallery/${slug}`);
  };

  useEffect(() => {
    const selectedImage = FilteredInspirationImages.filter(
      ({ node }) => node.strapiId === activeImage
    );
    if (selectedImage.length) {
      handleModalOpen(selectedImage[0]);
    }
  }, [activeImage]);

  useEffect(() => {
    const activeImageObj = FilteredInspirationImages[ModalImageIndex];
    if (activeImageObj) {
      const slug = `${activeImageObj.node.title
        .replace(/[^a-zA-Z\d:]/g, '-')
        .toLowerCase()}-${activeImageObj.node.strapiId}`;
      window.history.pushState('', '', `/gallery/${slug}`);
    } else {
      window.history.pushState('', '', `/gallery`);
    }
  }, [ModalImageIndex]);

  const hadleFilterChange = (filters: any) => {
    const appliedfilters = Object.fromEntries(
      // eslint-disable-next-line no-unused-vars
      Object.entries(filters).filter(([_, v]: any) => v.length !== 0)
    );

    const applyFilter = (list: any, filter: any) => {
      const allFilters: string[] = [];
      Object.keys(filter).forEach(filterKey => {
        allFilters.push(...filter[filterKey]);
      });
      if (allFilters.length) {
        return list.filter((obj: any) => {
          const imageTags = obj.node.inspiration_tags.map(
            (item: any) => item.title
          );

          let showImage = false;

          allFilters.forEach((filterItem: string) => {
            showImage = showImage || imageTags.includes(filterItem);
          });
          return showImage;
        });
      }
      return list;
    };
    const UpdatedInspirationImages = applyFilter(
      InspirationImages?.edges,
      appliedfilters
    );
    setFilteredInspirationImages(UpdatedInspirationImages);
  };
  useEffect(() => {
    hadleFilterChange(PageFilter);
  }, [PageFilter]);

  useEffect(() => {
    if (FilteredInspirationImages.length > imagesToDisplay) {
      setLoadMore(true);
    } else {
      setLoadMore(false);
    }
  }, [FilteredInspirationImages]);

  useEffect(() => {
    document.body.classList.add('inspiration-page');
    return () => {
      document.body.classList.remove('inspiration-page');
    };
  }, []);

  // TODO apply filter to InspirationImages
  return (
    <>
      <PageLayout
        title={title}
        quotation={quotation}
        metaTitle={seo?.metaTitle ?? title}
        description={seo?.metaDescription}
        image={seo?.shareImage?.publicURL}
        className="container"
      >
        <div className="inspiration-page">
          <h4 className="page-title"> &gt; {title}</h4>
          <div className="inspiration-section row">
            <div className="inspiration-section-filter col">
              <InspirationFilterList
                Filters={cmsFilters}
                setPageFilter={setPageFilter}
              />
            </div>
            <div className="inspiration-section-gallery-container col-lg-9">
              <div className="inspiration-section-gallery">
                {FilteredInspirationImages && (
                  <InspirationGallery
                    galleryItems={FilteredInspirationImages}
                    handleModalOpen={handleModalOpen}
                    imagesToDisplay={imagesToDisplay}
                  />
                )}
              </div>
              <div className="inspiration-section-gallery-load-more">
                {loadMore && (
                  <button type="button" onClick={() => loadMoreBlog()}>
                    Load more
                  </button>
                )}
              </div>
              <CTALink to={link}>{callToActionTitle}</CTALink>
            </div>
          </div>
        </div>
      </PageLayout>
      <InspirationGalleryModal
        ModalImageIndex={ModalImageIndex}
        isModalOpen={isModalOpen}
        setisModalOpen={setisModalOpen}
        FilteredInspirationImages={FilteredInspirationImages}
        setModalImageIndex={setModalImageIndex}
      />
    </>
  );
};

export default Inspiration;

export const query = graphql`
  query InspirationPageQuery {
    InspirationPage: strapiInspirationPage {
      title
      callToAction {
        link
        title
      }
      quotation {
        content
      }
      seo {
        metaDescription
        metaTitle
        shareImage {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 400, height: 400)
          }
        }
      }
    }
    InspirationImages: allStrapiInspirationImage {
      edges {
        node {
          strapiId
          title
          inspiration_tags {
            title
            inspiration_category
          }
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    InspirationCategories: allStrapiInspirationCategory {
      edges {
        node {
          id
          title
          inspiration_tags {
            id
            title
          }
        }
      }
    }
  }
`;
